<template>
  <div
    class="
      transition-colors
      duration-300
      flex
      flex-row
      items-baseline
      focus-within:border-blue-400
      dark:focus-within:border-blue-400
      py-1.5
      px-3
      sm:px-4
      h-10
      rounded-sm
      shadow-inner
      w-full
    "
    @click.stop="$refs.input && $refs.input.focus()"
  >
    <div
      class="
        flex-shrink-0
        -ml-1
        pointer-events-none
        text-sm
      "
    >
      <GMIcon
        name="search"
        class="-mb-1 mr-1 sm:mr-3 w-5 sm:w-6 h-5 sm:h-6"
        :class="{
          'text-green-400 placeholder-green-400': variant === 'primary',
          'text-blue-gray-400 placeholder-blue-gray-400': variant === 'secondary',
        }"
      />
    </div>

    <input
      ref="inputRef"
      v-model="inputModel"
      name="searchForestOwnerInput"
      :aria-controls="listboxId"
      :aria-activedescendant="activeItem ? `listboxOption_${activeItem.Id}` : ''"
      aria-autocomplete="list"
      :aria-label="placeholder"
      type="text"
      autocomplete="off"
      :placeholder="placeholder"
      class="
        flex-1
        bg-transparent
        focus:outline-none
        h-7
        min-w-0
        w-24
        sm:w-auto
      "
      :class="{
        'text-green-400 placeholder-green-400': variant === 'primary',
        'text-blue-gray-400 placeholder-blue-gray-400': variant === 'secondary',
      }"
      @keydown.esc="$refs.toggle && $refs.toggle.focus()"
      @input="onInput"
      @blur="onBlur"
      @focus="onFocus"
    />
    <slot name="post-input" />
    <div
      v-if="showDropdown"
      class="
        flex
        flex-shrink-0
        self-center
        -my-2
        -mr-2
        sm:-mr-4
        ml-1
        py-2
        text-sm
      "
    >
      <button
        ref="toggle"
        type="button"
        class="
          transition-colors
          duration-300
          px-1
          sm:px-4
          focus:outline-none
          border-l
        "
        :class="{
          'border-green-600': variant === 'primary',
          'border-gray-200': variant === 'secondary',
          'dark:border-gray-700': variant === 'secondary',
        }"
        :aria-label="$t('GO_TO_CUSTOMER_SEARCH')"
        @click.stop.prevent="!isSearchPanelOpen && focus()"
      >
        <GMIcon
          :name="isSearchPanelOpen ? 'chevron-up' : 'chevron-down'"
          class="block w-5 h-5"
          :class="{
            'text-green-500': variant === 'primary',
            'text-blue-gray-400 placeholder-blue-gray-400': variant === 'secondary',
          }"
        />
      </button>
    </div>
  </div>
</template>
<script lang="ts">

import { GMIcon, selectInputText } from '@gm/components'
import type {Customer} from '@/models/customers'
import type {PropType} from "@vue/runtime-core";
import type {Ref} from "@vue/reactivity";

export default defineComponent({
  components: {
    GMIcon
  },
  props: {
    activeItem: {
      type: Object as PropType<Customer>,
      default: () => ({Id: ''} as Customer)
    },
    selectInputTextOnFocus: {
      type: Boolean,
      default: true
    },
    modelValue: {
      type: [String, Number],
      default: ''
    },
    isSearchPanelOpen: {
      type: Boolean,
      default: false
    },
    listboxId: {
      type: String,
      required: true
    },
    variant: {
      type: String as PropType<'primary' | 'secondary'>,
      default: 'primary'
    },
    showDropdown: {
      type: Boolean,
      default: true
    },
    placeholder: {
      type: String,
      default: undefined
    }
  },
  emits: ['update:modelValue', 'active'],
  setup (props, { emit }) {
    const tempValue: Ref<string[]> = ref([])

    const setValue = (value: string) => {
      emit('update:modelValue', value)
    }

    const inputModel = computed({
      get (): string {
        const value = props.modelValue
        return value as string
      },
      set (value: string) {
        const parsedValue = value

        setValue(parsedValue)
      }
    })

    const onInput = ({ target }: { target: HTMLInputElement | null }) => {
      const { value } = target as HTMLInputElement
      if (!value) {
        tempValue.value = []
      }
    }

    const onBlur = ({ target }: { target: HTMLInputElement | null }) => {
      const { value } = target as HTMLInputElement
      emit('active', false)
      setValue(value)
    }

    const onFocus = async ({ target }: { target: HTMLInputElement | null }) => {
      if (target && props.selectInputTextOnFocus) {
        emit('active', true)
        // Prevent issue if the user quickly switches input while text select is in progress
        await new Promise(resolve => setTimeout(resolve, 100)) // Wait 100ms
        if (document.activeElement !== target) {
          return
        } // Abort if focused element has changed

        selectInputText(target)
      }
    }

    const inputRef = ref()
    const focus = () => {
      inputRef.value && (inputRef.value as HTMLElement)?.focus()
    }

    return {
      inputModel,
      onBlur,
      onInput,
      onFocus,
      focus,
      emit,
      inputRef,
    }
  }
})
</script>
